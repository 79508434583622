.social-telegram {
    padding: 4px 12px;
    gap: 2px;
    background-color: #F7F9FC;
    border-radius: 32px;
}

.telegram-china,
.social-twitter {
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 32px;
    background-color: #F7F9FC;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-telegram:hover {
    background-color: #1DA1F2;
    color: #ffffff;
    transition: 0.3s;
}

.telegram-china:hover {
    background-color: #ED4C5C;
    color: #ffffff;
    transition: 0.3s;
}

.social-twitter:hover {
    background-color: #000000;
    color: #ffffff;
    transition: 0.3s;
}