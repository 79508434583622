

.link {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.12;
    border-radius: 50%;
    z-index: 0;
}

.link-icon {
    position: relative;
    z-index: 1;
}

.twitter::before {
    background: var(--twitter-color);
}

.whatsApp::before {
    background: var(--whatsApp-color);
}

.telegram::before {
    background: var(--telegram-color);
}

.mail::before {
    background: var(--mail-color);
}

.facebook::before {
    background: var(--facebook-color);
}

.linkedIn::before {
    background: var(--linkedIn-color);
}

.copy::before {
    background: var(--copy-color);
}

.share::before {
    background: var(--share-color);
}

.twitter:hover {
    background-color: rgba(29, 161, 242, 0.16);
}

.whatsApp:hover {
    background: rgba(37, 211, 102, 0.16);
}

.telegram:hover {
    background: rgba(69, 174, 228, 0.16);
}

.mail:hover {
    background: rgba(250, 174, 123, 0.16);
}

.facebook:hover {
    background: rgba(24, 119, 242, 0.16);
}

.linkedIn:hover {
    background: rgba(0, 122, 183, 0.16);
}

.copy:hover {
    background: rgba(254, 132, 188, 0.16);
}

.share:hover {
    background: rgba(54, 207, 159, 0.16);
}