.home {
    min-height: 80vh;
}

.how-use-api-link:hover, .terms-link:hover {
    color: #488FEE;
}

.how-use-api-page {
    height: 75vh;
    width: 60%;
    box-shadow: 0 0 16px rgba(94, 106, 121, 0.06);
    border-radius: 8px;
}

.how-use-api-page h1 {
    padding-top: 2rem;
}

.terms-page {
    border-radius: 8px;
    width: 60%;
    box-shadow: 0 0 16px rgba(94, 106, 121, 0.06);
    padding-bottom: 1rem;
    margin-bottom: 3.5rem;
}

.layout {
    border-top: 4px solid #488FEE;
}

.support-mail-mob {
    display: none;
}

.home-wrapper {
    margin-bottom: 2rem;
    min-height: 75vh;
}

.terms-title {
   padding-top: 2rem;
}

.try-again {
    color: #5E6A79;
    font-weight: 400;
}

.try-again span {
    text-decoration: underline;
    margin-left: 10px;
}

.info-block {
    font-size: 20px;
}

.file-format {
    background: #F7F9FC;
    font-size: 12px;
    text-transform: uppercase;
}

.main-text {
    max-width: 520px;
}

@media screen and (max-width: 1600px) {
    .container {
        max-width: 1320px;
        padding: 0 55px;
    }
}

@media screen and (min-width: 916px) and (max-width: 1280px) {
    .links-block-icons {
        gap: .5rem;
    }

    .container {
        padding: 0 20px;
        max-width: 1200px;
    }

    .formats-block {
        padding: 1.75rem 1rem;
    }
}

@media screen and (max-width: 915px) {
    .main-text {
        max-width: 100%;
    }

    .container {
        padding: 0 20px;
    }

    .home-wrapper {
        flex-direction: column;
        padding-bottom: 1rem;
    }

    .content-block {
        width: 100%;
        gap: 1rem;
        margin-bottom: 1rem;
        min-height: 100%;
    }

    .file-upload-block {
        width: 100%;
    }

    .title {
        font-size: 48px;
    }

    .title-vector {
        width: 280px;
        margin-top: -1rem;
    }

    .sub-titles {
        font-size: 40px;
    }

    .statistics {
        width: 100%;
    }

    .home-content-text {
        font-size: 16px;
        line-height: 1.7;
    }

    .how-use-api-page, .terms-page {
        padding-top: 1rem;
        border-radius: 8px;
        width: 90%;
    }

    .terms-title {
        flex-direction: column;
    }

    .file-input {
        border: none;
    }
}

@media screen and (max-width: 768px) {
    .support-mail-mobile {
        display: block;
    }
    .support-mail-desktop {
        display: none;
    }
    .footer-content_text {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .footer-content {
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1.2rem;
        height: 104px;
    }
    .footer-links {
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 567px) {
    .links-block-icons {
        gap: .75rem;
    }

    .home-wrapper {
        position: relative;
    }

    .desktop-shared-links {
        display: none;
    }

    .mobile-shared-links {
        display: block;
        margin-bottom: 1.5rem;
        padding-left: 15px;
    }

    .links-block {
        white-space: nowrap;
        overflow-x: auto;
        max-width: 100%;
    }

    .links-block::-webkit-scrollbar {
        display: none;
    }

    .file-input:after {
        background: none;
    }

    .share-project {
        margin-right: 0.5rem;
    }

    .formats-block {
        padding: 15px;
        gap: 0.65rem;
    }

    .cloud-icon, .file-input-text {
        display: none;
    }

    .file-input-btn {
        width: 100%;
    }

    .header {
        height: 40px;
        margin-bottom: 1rem;
    }

    .br {
        display: none;
    }

    .support-mail-mob {
        display: block;
    }

    .container {
        padding: 0 15px;
    }

    .info-block {
        font-size: 16px;
    }

    .border-error {
        border: 2px solid #E26464;
    }

    .border-success {
        border: 2px solid #3DADA2;
    }

    .border-upload {
        border: 2px solid #488FEE;
    }

    .mobile-padding {
        padding: 50px 35px;
    }

    .mobile-padding-default {
        padding: 0;
        border: none;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        padding: 20px 15px;
        height: auto;
    }

    .footer-links {
        width: 100%;
        justify-content: space-between;
    }

    .desktop-footer-text {
        display: none;
    }

    .mobile-footer-text {
        display: block;
    }
}