@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    --twitter-color: #1DA1F2;
    --whatsApp-color: #25D366;
    --telegram-color: #45AEE4;
    --mail-color: #FAAE7B;
    --facebook-color: #1877F2;
    --linkedIn-color: #007AB7;
    --copy-color: #FE84BC;
    --share-color: #36CF9F;
    --neutral_1: #222A35;
    --neutral_2: #5E6A79;
    --neutral_3: #8895A7;
}

.neutral-black
{
    color: var(--neutral_1);
}

.neutral-dark-grey {
    color: var(--neutral_2);
}

.neutral-grey {
    color: var(--neutral_3);
}

body {
    background: url("./img/background.png") no-repeat center;
    background-size: cover;
    font-family: "Open Sans", sans-serif;
}

img {
    max-width: none;
}

.container {
    padding: 0 25px;
}

@media screen and (max-width: 1150px) {
    body {
        background: url("./img/background-mobile.png") no-repeat center;
        background-size: cover;
    }
}