.file-input-wrapper {
    position: relative;
    border-radius: 16px;
    z-index: 10;
    box-sizing: border-box;
    padding: 2px;
}

.file-input{
    border-radius: 14px;
    cursor: pointer;
    background-color: white;
}

.file-input-drag {
    border: 2px solid #488FEE;
    border-radius: 16px;
    background: #F6FDFF;
    min-width: 100.6%;
    height: 100.6%;
}

.border-error,
.border-success,
.border-upload {
    min-width: 100.6%;
    height: 100.6%;
}

.file-input-btn {
    border: 4px solid rgba(116, 222, 247, 0.16);
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.file-input-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #478EEE 1.31%, #75E0F7 100%);
    z-index: -1;
}

.file-input-btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    z-index: 0;
}

.dropzone-border {
    position: absolute;
    display: block;
    inset: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 567px) {
    .file-input {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
    }

    .dropzone-border {
        display: none;
    }

    .file-input:before {
        display: none;
    }

    .file-input:hover {
        border: none;
    }
}